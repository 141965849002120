import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  padding: 20px 30px;
`;
export const Header = styled.div`
  /* padding: 10px 0; */
`;
export const Title = styled.span`
  font-size: 40px;
`;
export const Body = styled.div``;
export const ContainerDescription = styled.div`
  padding: 20px 0 60px 0;
`;
export const Description = styled.p`
  font-size: 16px;
`;
export const RequestContainer = styled.div`
  background-color: #231f20;
  color: #fff;
  padding: 40px 20px;
  border-bottom: 1px solid #fff;
`;
export const RequestContent = styled.div``;
export const RequestJsonContainer = styled.div`
  padding: 20px 0;
  display: flex;
`;
export const RequestJsonSubContainer = styled.div`
  padding: 10px 0;
`;
export const RequestRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const RequestLeft = styled.div`
  width: 50%;
`;
export const RequestRight = styled.div`
  width: 50%;
`;
