import React, { createContext, useContext, useEffect, useState } from 'react';
import authService from '../services/authService';
import authStorage from '../storage/authStorage';
interface ILogin {
  id: string;
  secret: string;
}
interface IAuthContext {
  token: string;
  loading: boolean;
  setToken: (data: string) => void;
  login: (data: ILogin) => void;
  logout: () => void;
}
export const AuthContext = createContext<IAuthContext>({
  token: '',
  setToken: (data) => {},
  loading: true,
  logout: () => {},
  login: (data: ILogin) => {},
});
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const login = async ({ id, secret }: ILogin) => {
    try {
      const res = await authService.login({ id, secret });

      if (res.access_token) {
        setToken(res.access_token);
      }
    } catch (error: any) {
      setToken('');
      console.log(error);
      throw new Error(error);
    }
  };
  const logout = async () => {
    setToken('');
    authStorage.remove();
  };
  useEffect(() => {
    const _token = authStorage.get();
    if (_token) {
      setToken(_token);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    authStorage.set(token);
  }, [token]);
  return (
    <AuthContext.Provider
      value={{
        token,
        loading,
        setToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
