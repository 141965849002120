import apiInternal from './servers/apiInternal';

const consultaWeb = {
  request: async ({ requestId }: { requestId: string }) => {
    const res = await apiInternal.get(`/request/${requestId}`);
    return res.data;
  },
  autoRequest: async ({ cnj }: { cnj: string }) => {
    const res = await apiInternal.post(`/autorequest`, { value: cnj });
    return res.data;
  },
};
export default consultaWeb;
