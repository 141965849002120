import styled from 'styled-components';

export const Container = styled.div``;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #445156;
`;
export const LeftHeader = styled.div``;
export const RightHeader = styled.div``;
export const Link = styled.span<{ $active?: boolean }>`
  padding: 0 30px;
  cursor: pointer;
  color: #fff;
  ${(props) => (props.$active ? `text-decoration: underline;` : '')}
  &:hover {
    color: #2ceb94;
  }
`;
export const Body = styled.div``;
