import React, { useRef } from 'react';
import Dashboard from '../../components/templates/Dashboard';
import { useRequest } from '../../hooks/useRequest';
import { Body, Button, ButtonContainer, Container, ContainerDescription, Content, Description, Form, Header, Textarea, Title } from './styles';
import { useNavigate } from 'react-router-dom';

const RequestContent: React.FC = () => {
  const { setRequests } = useRequest();
  const navigate = useNavigate();
  const requestsIdRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const requestsId = requestsIdRef.current && requestsIdRef.current.value;
    if (requestsId) {
      const idList = requestsId.split('\n');
      if (idList.length > 0) {
        setRequests(idList);
      } else {
        setRequests([]);
      }
      navigate('/request/monitoring');
    }
  };
  return (
    <Container>
      <Content>
        <Header>
          <Title>Request</Title>
        </Header>
        <Body>
          <ContainerDescription>
            <Description>Digite os ids dos requests que deseja monitorar o status e o retorno</Description>
            <Description>Cada linha deve ser um id, da seguinte forma:</Description>
            <span>203f8cfb-2dcc-4231-8ec7-b5c99d13ea8c</span>
            <br />
            <span>f0f58048-fd6d-4819-bf7b-f2bdac851f45</span>
            <br />
            <span>35dc27c5-b4df-4728-ab9d-1ccf36fcbb39</span>
            <br />
            <span>02a89d9a-4ccb-4da8-a0b4-bf0139cf5b96</span>
          </ContainerDescription>
          <Form onSubmit={handleSubmit}>
            <Textarea ref={requestsIdRef} name="requestsId" placeholder="203f8cfb-2dcc-4231-8ec7-b5c99d13ea8c" />
            <ButtonContainer>
              <Button>Monitorar</Button>
            </ButtonContainer>
          </Form>
        </Body>
      </Content>
    </Container>
  );
};
const Request: React.FC = () => {
  return (
    <Dashboard>
      <RequestContent />
    </Dashboard>
  );
};

export default Request;
