import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import AutoRequest from './pages/AutoRequest';
import Login from './pages/Login';
import Request from './pages/Request';
import RequestMonitoring from './pages/RequestMonitoring';
import Loading from './pages/Loading';
import AutoRequestMonitoring from './pages/AutoRequestMonitoring';
import GetIdDemand from './pages/GetIdDemand';
import GetIdDemandShow from './pages/GetIdDemandShow';

const PrivateRoute: React.FC = () => {
  const { token, loading } = useAuth();
  const location = useLocation();
  if (loading) {
    return <Loading />;
  }

  return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};
const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/auto-request/monitoring" element={<AutoRequestMonitoring />} />
          <Route path="/auto-request" element={<AutoRequest />} />
          <Route path="/request/monitoring" element={<RequestMonitoring />} />
          <Route path="/request" element={<Request />} />
          <Route path="/demand-id" element={<GetIdDemand />} />
          <Route path="/demand-id/:id" element={<GetIdDemandShow />} />
          <Route path="/" element={<AutoRequest />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
