import React, { createContext, useContext, useState } from 'react';
import consultaWeb from '../services/consultaWeb';

interface IAutoRequestContext {
  cnjs: string[];
  setCnjs: (data: string[]) => void;
  createAutoRequest: (request: string) => any;
}
export const AutoRequestContext = createContext<IAutoRequestContext>({
  cnjs: [],
  setCnjs: (data) => {},
  createAutoRequest: (request: string) => {},
});
const AutoRequestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cnjs, setCnjs] = useState<string[]>([]);
  const createAutoRequest = async (cnj: string): Promise<any> => {
    try {
      let response = await consultaWeb.autoRequest({ cnj });
      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  };
  return (
    <AutoRequestContext.Provider
      value={{
        cnjs,
        setCnjs,
        createAutoRequest,
      }}
    >
      {children}
    </AutoRequestContext.Provider>
  );
};

const useAutoRequest = (): IAutoRequestContext => {
  const context = useContext(AutoRequestContext);
  if (!context) {
    throw new Error('useAutoRequest must be used within an AutoRequestProvider');
  }
  return context;
};

export { AutoRequestProvider, useAutoRequest };
