import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../components/templates/Dashboard";
import { useIdDemand } from "../../hooks/useIdDemand";
import {
  Body,
  Button,
  ButtonContainer,
  Container,
  ContainerDescription,
  Content,
  Description,
  Form,
  Header,
  Textarea,
  Title,
  Input,
  InputContainer,
  InputLabel,
} from "./styles";

const GetIdDemandContent: React.FC = () => {
  const { getIdDemand } = useIdDemand();
  const navigate = useNavigate();
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState<any>({});
  const idDemand: any = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Content>
        <Header>
          <Title>Busca por idDemanda</Title>
        </Header>

        <Body>
          <ContainerDescription>
            <Description>
              Digite o idDemanda que deseja buscar nos seguintes ambientes
            </Description>
            <span>Codilo DT - Projuris</span>
            <br />
            <span>Codilo DT - Saj</span>
            <br />
            <span>Codilo DT - Peticiona</span>
            <br />
            <span>Codilo DT - Dev</span>
            <br />
            <span>Datateam antigo - Projuris</span>
            <br />
            <span>Datateam antigo - Saj</span>
            <br />
            <span>Datateam antigo - Prod</span>
            <br />
            <Description>
              A busca será realizar em cada um desses ambiente na ordem acima
            </Description>
          </ContainerDescription>
          <Form
            onSubmit={(e: any) => {
              e.preventDefault();
              navigate(
                "/demand-id/" + idDemand.current && idDemand.current.value
              );
            }}
          >
            <InputContainer>
              {/* <InputLabel>ID DEMANDA</InputLabel> */}
              <Input
                ref={idDemand}
                type="text"
                name="idDemand"
                required
                placeholder="Digite o Id de busca"
              />
            </InputContainer>
            <ButtonContainer>
              <Button>Buscar</Button>
            </ButtonContainer>
          </Form>
        </Body>
      </Content>
    </Container>
  );
};
const GetIdDemand: React.FC = () => {
  return (
    <Dashboard>
      <GetIdDemandContent />
    </Dashboard>
  );
};

export default GetIdDemand;
