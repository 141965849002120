import axios from 'axios';
import authStorage from '../../storage/authStorage';

const { REACT_APP_SERVER_HOST_INTERNAL: SERVER_HOST } = process.env;

// Criando apiInternal para comunicação com servidor
const apiInternal = axios.create({
  baseURL: SERVER_HOST,
});

// Adicionando interceptor para inserir token Bearer de autenticação no header
apiInternal.interceptors.request.use(
  async (config) => {
    const token = await authStorage.get();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token || ''}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiInternal;
