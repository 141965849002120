import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Oval } from 'react-loading-icons';

import { Body, Container, Content, Header, HeaderLeft, HeaderRight, HeaderRow } from './styles';
import { useRequest } from '../../hooks/useRequest';
import requestTime from '../../constants/requestTime';

const RequestViewer: React.FC<{ requestId: string }> = ({ requestId }) => {
  const { getRequest } = useRequest();
  const [requestValue, setRequestValue] = useState<any>(null);
  const [countTime, setCountTime] = useState<number>(requestTime.requestSeg);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('loading');
  const statusKeys: { [key: string]: string } = {
    pending: 'yellow',
    success: 'green',
    warning: 'orange',
    error: 'red',
    loading: 'white',
  };
  useEffect(() => {
    const updateRequest = async () => {
      setLoading(true);

      try {
        let result = await getRequest(requestId);

        if (result.success) {
          setRequestValue(result);
          setStatus(result.requested.status);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    updateRequest();

    const interval = setInterval(() => {
      if (status === 'pending' || status === 'loading') {
        updateRequest();
      } else {
        clearInterval(interval);
      }
    }, requestTime.requestMili);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (!loading) {
      if (countTime > 0) {
        setTimeout(() => setCountTime(countTime - 1), 1000);
      } else {
        setCountTime(10);
      }
    }
  }, [countTime, loading]);
  return (
    <Container>
      <Content>
        <Header>
          <HeaderRow>
            <HeaderLeft>
              RequestId: <b>{requestId}</b>
            </HeaderLeft>
          </HeaderRow>
          <HeaderRow>
            <HeaderLeft>
              {status === 'pending' || status === 'loading' ? (
                <>
                  Reiniciando em: <b>{countTime}</b>
                </>
              ) : (
                'Requisição finalizada!!'
              )}
            </HeaderLeft>
            <HeaderRight style={{ color: statusKeys[status] }}>{status.toUpperCase()}</HeaderRight>
          </HeaderRow>
        </Header>
        <Body>
          {requestValue ? (
            <ReactJson
              {...{
                quotesOnKeys: false,
                displayDataTypes: false,
                indentWidth: 2,
                theme: 'tube',
                name: 'response',
                collapsed: true,
                src: requestValue,
              }}
            />
          ) : (
            <Oval height={20} stroke="#fff" />
          )}
        </Body>
      </Content>
    </Container>
  );
};

export default RequestViewer;
