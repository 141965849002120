import apiInternal from './servers/apiInternal';

const authService = {
  login: async ({ grant_type = 'client_credentials', id, secret }: { grant_type?: string; id: string; secret: string }) => {
    const res = await apiInternal.post('auth', { grant_type, id, secret });

    return res.data;
  },
};
export default authService;
