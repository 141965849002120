import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  padding: 20px 30px;
`;
export const Header = styled.div`
  /* padding: 10px 0; */
`;
export const Title = styled.span`
  font-size: 40px;
`;
export const Body = styled.div``;
export const ContainerDescription = styled.div`
  padding: 20px 0 20px 0;
`;
export const Description = styled.p`
  font-size: 16px;
`;
export const Form = styled.form``;
export const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid #1e1e1e;
  min-height: 400px;
`;
export const Button = styled.button`
  margin: 15px 0;
  padding: 10px;
  height: 50px;
  background-color: #1e1e1e;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  color: #fff;
  &:hover {
    background-color: #2ceb94;
    color: #0f0f0f;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;
export const InputLabel = styled.span``;
export const Input = styled.input`
  background-color: #eaf1f4;
  height: 30px;
  padding: 10px 5px;
  margin: 5px 0;
`;
