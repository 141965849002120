import axios from 'axios';

const codiloDtProjuris = axios.create({
  baseURL: 'https://api.projuris.datateam.codilo.com.br/v1',
  // headers: { Origin: window.location.origin },
});
const codiloDtSaj = axios.create({
  baseURL: 'https://api.sajadv.datateam.codilo.com.br/v1',
  // headers: { Origin: window.location.origin },
});

const codiloDtDev = axios.create({
  baseURL: 'https://api.dev.datateam.codilo.com.br/v1',
  // headers: { Origin: window.location.origin },
});
const datateamProd = axios.create({
  baseURL: 'https://u3dzcrieu3.execute-api.us-east-1.amazonaws.com/prod',
  // headers: { Origin: window.location.origin },
});
const datateamSaj = axios.create({
  baseURL: 'https://qeywio01ce.execute-api.us-east-1.amazonaws.com/sajadv',
  // headers: { Origin: window.location.origin },
});
const datateamProjuris = axios.create({
  baseURL: 'https://u9skxw4ua8.execute-api.sa-east-1.amazonaws.com/projuris',
  // headers: { Origin: window.location.origin },
});

export const apiDemand = {
  codiloDtProjuris,
  codiloDtSaj,
  codiloDtDev,
  datateamProd,
  datateamSaj,
  datateamProjuris,
};
