import React, { createContext, useContext, useState } from 'react';
import consultaWeb from '../services/consultaWeb';
import demandConsult from '../services/demandConsult';

interface IIdDemandContext {
  // cnjs: string[];
  // setCnjs: (data: string[]) => void;
  getIdDemand: (demandId: string) => any;
}
export const IdDemandContext = createContext<IIdDemandContext>({
  // cnjs: [],
  // setCnjs: (data) => {},
  getIdDemand: (demandId: string) => {},
});
const IdDemandProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const [cnjs, setCnjs] = useState<string[]>([]);
  const getIdDemand = async (demandId: string): Promise<any> => {
    try {
      let res = await demandConsult.getDemand({ demandId });
      return res;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  };
  return (
    <IdDemandContext.Provider
      value={{
        getIdDemand,
      }}
    >
      {children}
    </IdDemandContext.Provider>
  );
};

const useIdDemand = (): IIdDemandContext => {
  const context = useContext(IdDemandContext);
  if (!context) {
    throw new Error('useIdDemand must be used within an IdDemandProvider');
  }
  return context;
};

export { IdDemandProvider, useIdDemand };
