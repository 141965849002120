import React from 'react';
import { Container } from './styles';
import { Grid } from 'react-loading-icons';

const Loading: React.FC = () => {
  return (
    <Container>
      <Grid height={500} stroke="#fff" />
    </Container>
  );
};

export default Loading;
