import React from 'react';
import { AuthProvider } from './hooks/useAuth';
import { RequestProvider } from './hooks/useRequest';
import { AutoRequestProvider } from './hooks/useAutoRequest';
import { IdDemandProvider } from './hooks/useIdDemand';

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AuthProvider>
      <RequestProvider>
        <IdDemandProvider>
          <AutoRequestProvider>{children}</AutoRequestProvider>
        </IdDemandProvider>
      </RequestProvider>
    </AuthProvider>
  );
};

export default Providers;
