import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../components/templates/Dashboard";
import { useIdDemand } from "../../hooks/useIdDemand";
import { Body, Button, ButtonContainer, Container, ContainerDescription, Content, Description, Form, Header, Textarea, Title, Input, InputContainer, InputLabel } from "./styles";
import Loading from "../Loading";
import ReactJson from "react-json-view";

const GetIdDemandShowContent: React.FC = () => {
  const { getIdDemand } = useIdDemand();

  let { id } = useParams();
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState<any>({});

  const options: any = {
    "codilo-dt-projuris": "Codilo dt Projuris",
    "codilo-dt-saj": "Codilo dt Saj",
    "codilo-dt-peticiona": "Codilo dt Peticiona",
    "codilo-dt-dev": "Codilo dt Dev",
    "datateam-prod": "Datateam Prod (DESATIVADO)",
    "datateam-saj": "Datateam Saj (DESATIVADO)",
    "datateam-projuris": "Datateam Projuris (DESATIVADO)",
  };

  useEffect(() => {
    const getData = async () => {
      if (!id) return;
      try {
        const res = await getIdDemand(id);
        console.log(res);
        setResponse(res);
        setShowResponse(true);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]);
  // const call = async ()=>{}

  return !showResponse ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Header>
          <Title>Id demanda: {id}</Title>
          <Title>
            Origem: <span style={{ color: "red" }}>{options[response.key] || "Não encontrada"}</span>
          </Title>
        </Header>

        <Body>
          <ContainerDescription>
            <ReactJson
              {...{
                quotesOnKeys: false,
                displayDataTypes: false,
                indentWidth: 2,
                theme: "tube",
                name: response.key,
                collapsed: true,
                src: response.data,
              }}
            />
          </ContainerDescription>
        </Body>
      </Content>
    </Container>
  );
};
const GetIdDemandShow: React.FC = () => {
  return (
    <Dashboard>
      <GetIdDemandShowContent />
    </Dashboard>
  );
};

export default GetIdDemandShow;
