import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
`;
export const Content = styled.div``;
export const Header = styled.div``;
export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;
export const HeaderLeft = styled.div``;
export const HeaderRight = styled.div``;
export const Body = styled.div``;
