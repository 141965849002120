import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../components/templates/Dashboard';
import { useAutoRequest } from '../../hooks/useAutoRequest';
import { Body, Button, ButtonContainer, Container, ContainerDescription, Content, Description, Form, Header, Textarea, Title } from './styles';

const AutoRequestContent: React.FC = () => {
  const { setCnjs } = useAutoRequest();
  const navigate = useNavigate();
  const cnjsRef = useRef<HTMLTextAreaElement>(null);
  // const call = async ()=>{}
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const cnjs = cnjsRef.current && cnjsRef.current.value;
    if (cnjs) {
      const cnjList = cnjs.split('\n');
      if (cnjList.length > 0) {
        setCnjs(cnjList);
      } else {
        setCnjs([]);
      }
      navigate('/auto-request/monitoring');
    }
  };
  return (
    <Container>
      <Content>
        <Header>
          <Title>Auto Request</Title>
        </Header>
        <Body>
          <ContainerDescription>
            <Description>Digite os CNJs que deseja monitorar o status e o retorno</Description>
            <Description>Cada linha deve ser um CNJ, da seguinte forma:</Description>
            <span>5003060-23.2015.4.04.7209</span>
            <br />
            <span>5010205-96.2016.4.04.7209</span>
            <br />
            <span>5013857-07.2023.8.13.0027</span>
            <br />
            <span>5000042-64.2019.4.04.7205</span>
            <br />
          </ContainerDescription>
          <Form onSubmit={handleSubmit}>
            <Textarea ref={cnjsRef} name="cnjs" placeholder="5007878-28.2018.4.04.7204" />
            <ButtonContainer>
              <Button>Monitorar</Button>
            </ButtonContainer>
          </Form>
        </Body>
      </Content>
    </Container>
  );
};
const AutoRequest: React.FC = () => {
  return (
    <Dashboard>
      <AutoRequestContent />
    </Dashboard>
  );
};

export default AutoRequest;
