import React from 'react';
import RequestViewer from '../../components/RequestViewer';
import Dashboard from '../../components/templates/Dashboard';
import { useRequest } from '../../hooks/useRequest';
import { Body, Container, ContainerDescription, Content, Description, Header, RequestContainer, RequestContent, Title } from './styles';

const RequestMonitoringContent: React.FC = () => {
  const { requests } = useRequest();

  return (
    <Container>
      <Content>
        <Header>
          <Title>Monitoramento de Requests</Title>
        </Header>
        <Body>
          <ContainerDescription>
            <Description>Requests Sendo monitorados</Description>
          </ContainerDescription>
          {requests.map((request, idx) => (
            <RequestContainer key={idx}>
              <RequestContent>
                <RequestViewer requestId={request} />
              </RequestContent>
            </RequestContainer>
          ))}
        </Body>
      </Content>
    </Container>
  );
};
const RequestMonitoring: React.FC = () => {
  return (
    <Dashboard>
      <RequestMonitoringContent />
    </Dashboard>
  );
};

export default RequestMonitoring;
