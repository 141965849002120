import React, { createContext, useContext, useState } from 'react';
import consultaWeb from '../services/consultaWeb';

interface IRequestContext {
  requests: string[];
  setRequests: (data: string[]) => void;
  getRequest: (request: string) => any;
}
export const RequestContext = createContext<IRequestContext>({
  requests: [],
  setRequests: (data) => {},
  getRequest: (request: string) => {},
});
const RequestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [requests, setRequests] = useState<string[]>([]);
  const getRequest = async (requestId: string): Promise<any> => {
    try {
      let response = await consultaWeb.request({ requestId });
      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  };
  return (
    <RequestContext.Provider
      value={{
        requests,
        setRequests,
        getRequest,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

const useRequest = (): IRequestContext => {
  const context = useContext(RequestContext);
  if (!context) {
    throw new Error('useRequest must be used within an RequestProvider');
  }
  return context;
};

export { RequestProvider, useRequest };
