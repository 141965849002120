import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  /* background-color: #445156; */
`;
export const Content = styled.div`
  background-color: #445156;
  min-width: 400px;
  padding: 60px 40px;
`;
export const Header = styled.div`
  padding: 10px 0;
`;
export const Title = styled.span`
  font-size: 20px;
`;
export const Body = styled.div`
  padding: 10px 0;
`;
export const Form = styled.form``;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;
export const InputLabel = styled.span``;
export const Input = styled.input`
  height: 30px;
  padding: 10px 5px;
  margin: 5px 0;
`;
export const Button = styled.button`
  margin: 15px 0;
  padding: 10px;
  height: 50px;

  cursor: pointer;
  width: 100%;
  font-weight: 700;
  color: #445156;
  &:hover {
    background-color: #2ceb94;
    color: #445156;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
