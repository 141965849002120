import React from 'react';
import { Body, Container, Header, LeftHeader, Link, RightHeader } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

// import { Container } from './styles';

const Dashboard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const linksHeader = [
    {
      label: 'Auto-request',
      path: '/auto-request',
    },
    {
      label: 'Request',
      path: '/request',
    },
    {
      label: 'Id Demanda',
      path: '/demand-id',
    },
  ];

  return (
    <Container>
      <Header>
        <LeftHeader>
          {linksHeader.map((link, idx) => (
            <Link
              key={idx}
              $active={location.pathname === link.path}
              onClick={() => navigate(link.path)}
            >
              {link.label}
            </Link>
          ))}
        </LeftHeader>
        <RightHeader>
          <Link onClick={logout}>Logout</Link>
        </RightHeader>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default Dashboard;
