import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Oval } from 'react-loading-icons';
import RequestViewer from '../../components/RequestViewer';
import Dashboard from '../../components/templates/Dashboard';
import { useAutoRequest } from '../../hooks/useAutoRequest';
import { Body, Container, ContainerDescription, Content, Description, Header, RequestContainer, RequestContent, RequestJsonContainer, RequestJsonSubContainer, RequestLeft, RequestRight, RequestRow, Title } from './styles';

const AutoRequestMonitoringContent: React.FC = () => {
  const { cnjs, createAutoRequest } = useAutoRequest();

  const [autoRequestValue, setAutoRequestValue] = useState<any>({});

  useEffect(() => {
    const createAllAutoRequests = async () => {
      let _autoRequestValue: any = { ...autoRequestValue };
      for (let i = 0; i < cnjs.length; i += 1) {
        try {
          let result = await createAutoRequest(cnjs[i]);
          Object.assign(_autoRequestValue, { [cnjs[i]]: result });
        } catch (error) {
          console.log(error);
        }
      }

      setAutoRequestValue(_autoRequestValue);
    };
    createAllAutoRequests();
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <Title>Monitoramento de Auto Requests - CNJ</Title>
        </Header>
        <Body>
          <ContainerDescription>
            <Description>Auto Requests sendo monitorados</Description>
          </ContainerDescription>
          {cnjs.map((cnj, idx) => (
            <RequestContainer key={idx}>
              <RequestContent>
                <RequestRow>
                  <RequestLeft>
                    CNJ: <b>{cnj}</b>
                  </RequestLeft>
                  <RequestRight>
                    {' '}
                    Auto Request Id: <b>{autoRequestValue[cnj] ? `${autoRequestValue[cnj].data.id}` : ''}</b>
                  </RequestRight>
                </RequestRow>
                {/* <RequestRow></RequestRow> */}
              </RequestContent>
              <RequestJsonContainer>
                <RequestLeft>
                  {autoRequestValue[cnj] ? (
                    <ReactJson
                      {...{
                        quotesOnKeys: false,
                        displayDataTypes: false,
                        indentWidth: 2,
                        theme: 'tube',
                        name: `auto-request`,
                        collapsed: true,
                        src: autoRequestValue[cnj],
                      }}
                    />
                  ) : (
                    <Oval height={20} stroke="#fff" />
                  )}
                </RequestLeft>
                <RequestRight>
                  <RequestJsonSubContainer>{autoRequestValue[cnj] ? autoRequestValue[cnj].data.requests.map((request: any, idx: number) => <RequestViewer key={idx} requestId={request.id} />) : <Oval height={20} stroke="#fff" />}</RequestJsonSubContainer>
                </RequestRight>
              </RequestJsonContainer>
            </RequestContainer>
          ))}
        </Body>
      </Content>
    </Container>
  );
};
const AutoRequestMonitoring: React.FC = () => {
  return (
    <Dashboard>
      <AutoRequestMonitoringContent />
    </Dashboard>
  );
};

export default AutoRequestMonitoring;
