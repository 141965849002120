import React, { useRef, useState } from 'react';
import { Oval } from 'react-loading-icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Body, Button, ButtonContainer, Container, Content, Form, Header, Input, InputContainer, InputLabel, Title } from './styles';
// import
// import { Container } from './styles';

const Login: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const idRef = useRef<HTMLInputElement>(null);
  const secretRef = useRef<HTMLInputElement>(null);
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const id = (idRef.current && idRef.current.value) || '';
      const secret = (secretRef.current && secretRef.current.value) || '';
      await login({ id, secret });
      navigate('/');
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };
  return (
    <Container>
      <Content>
        <Header>
          <Title>Login</Title>
        </Header>
        <Body>
          <Form onSubmit={handleSubmit}>
            <InputContainer>
              <InputLabel>Id</InputLabel>
              <Input ref={idRef} type="string" name="id" required />
            </InputContainer>
            <InputContainer>
              <InputLabel>Secret</InputLabel>
              <Input ref={secretRef} type="string" name="secret" required />
            </InputContainer>
            <ButtonContainer>
              <Button>{loading ? <Oval height={30} stroke="#445156" /> : 'ENTRAR'}</Button>
            </ButtonContainer>
          </Form>
        </Body>
      </Content>
    </Container>
  );
};

export default Login;
